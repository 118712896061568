<template>
    <div class="dersler">
        <button @click="show('Computer Network')" class="ders">Computer Network</button>
        <button @click="show('HCI')" class="ders">HCI</button>
        <button @click="show('Digital Logic System')" class="ders">Digital Logic System</button>
        <button @click="show('Engineer Ethics')" class="ders">Engineer Ethics</button>
        <button @click="show('Microprocessor')" class="ders">Microprocessor</button>
        <button @click="show('Programin Languages')" class="ders">Programming Languages</button>
    </div>
    <div class="container">
        <subject-box
            :subjects="boxSubjects"
            :allSubjects="boxSubjectsAll"
        ></subject-box>
    </div>
</template>

<script>
    import SubjectBox from './SubjectBox.vue';
    import axios from 'axios';

    export default {
        name : 'SubjectPage',
        components : {
            SubjectBox : SubjectBox
        },
        data(){
            return {
                boxSubjects : [
                    {   
                        title : 'Yükleniyor',
                        languages : ['Yükleniyor'],
                        description : 'Yükleniyor',
                        link : ''
                    }
                ],
                boxSubjectsAll : [],
                tokenId : null
            }
        },
        created(){
            axios.get("/subjects.json")
            .then((response)=>{
                this.boxSubjects = response.data
                this.boxSubjectsAll = response.data
            })
            .catch((e)=>{e})
            axios.get('token.json')
                .then(response=>{
                    this.tokenId = response.data.value
                    if(!this.tokenId){
                        window.location.href = '/admin'
                    }
                })
                .catch((e)=>{e})
        },
        methods: {
            show(lesson){
                axios.get("/subjects.json")
                    .then((response)=>{
                        let data = Object.values(response.data);
                        let length = data.length
                        this.boxSubjectsAll = response.data
                        let arr = []
                        for(let i=0; i<length; i++){
                            let length2 = data[i].languages.length
                            for(let j=0; j<length2; j++){
                                if((data[i].languages[j]).toLowerCase() === lesson.toLowerCase()){
                                    arr.push(data[i])
                                }
                            }
                        }
                        this.boxSubjects = arr
                    })
                    .catch((e)=>{e})
            }
        },
    }
</script>

<style scoped>
    .container {
        width: 100%;
        height: 100%;
    }
    .dersler {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 40px;
    }
    .ders {
        display: flex;
        text-decoration: none;
        cursor: pointer;
        color: black;
        font-size: 12px;
        font-weight: bold;
        align-items: center;
        width: 20%;
        justify-content: center;
        border: 1px solid rgba(0, 0, 0, 0.369);
        transition: .5s ease background;
    }
    .ders:hover {
        background-color: rgba(35, 189, 224, 0.478);
    }
    
</style>