<template>
    <div>
        <div v-for="(post , index) in posts" :key="index" class="box">
            <div class="title">
                {{ post.title }}
            </div>
            <hr>
            <div class="use-lang">
                <div v-for="language in post.languages" :key="language" class="languages">{{ language }}</div>
            </div>
            <hr>
            <router-link :to="'/projects/' + newIndex" @mouseover="sendIndex(post)" class="button">
                <div
                    
                    class="btnMore"
                >
                    <i class="fas fa-arrow-right"></i>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name : 'ProjectBox',
        props : {
            'posts' : Array,
        },
        data(){
            return {
                newIndex : '',
                cutDesc : ''
            }
        },
        methods:{
            sendIndex(post){
                let arr = Object.values(this.posts)
                this.newIndex = arr.indexOf(post)
            }
        }
    }
</script>

<style scoped>
    @media only screen and (min-width: 890px) {
        .box {
            width: 60%;
            margin-left: 19%;
            padding: 20px;
            border: 1px solid rgba(0, 0, 0, 0.276);
            background-color: rgba(240, 248, 255, 0.372);
            margin-top: 30px;
            border-radius: 5px;
        }
        .title {
            font-size: 20px;
            font-weight: bold;
        }
        .use-lang{
            display: flex;
            flex-wrap: wrap;
        }
        .languages {
            font-size: 15px;
            font-weight: bold;
            padding: 4px;
            border: 1px solid rgb(20, 23, 23);
            margin: 1px;
            border-radius: 5px;
        }
        .languages:hover {
            background-color: rgba(35, 189, 224, 0.478);
            padding-left: 10px;
            padding-right: 10px;
            font-size: 15px;
            cursor: none;
        }
        .short-desc {
            font-size: 15px;
        }
        .button {
            display: flex;
            justify-content: end;
            margin-top: 10px;
            text-decoration: none;
        }
        .btnMore {
            color: rgba(0, 0, 0, 0.534);
            font-size: 15px;
            padding: 10px;
            background-color: rgba(35, 189, 224, 0.26);
            border-radius: 5px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            transition: padding .3s ease;
        }
        .btnMore:hover {
            background-color: rgb(255, 255, 255);
            padding-left: 15px;
            padding-right: 15px;
            cursor: pointer;
        }
    }
    @media only screen and (max-width: 890px) {
        .box {
            width: 80%;
            margin-left: 6%;
            padding: 20px;
            background-color: rgba(240, 248, 255, 0.372);
            border: 1px solid rgba(0, 0, 0, 0.091);
            margin-top: 20px;
            border-radius: 20px;
        }
        .title {
            font-size: 25px;
            font-weight: bold;
        }
        .use-lang{
            display: flex;
            flex-wrap: wrap;
        }
        .languages {
            font-size: 12px;
            font-weight: bold;
            padding: 4px;
            border: 1px solid rgba(0, 0, 0, 0.118);
            background-color: rgba(35, 189, 224, 0.093);
            transition: padding .5s ease;
            margin: 1px;
            border-radius: 5px;
        }
        .languages:hover {
            background-color: rgba(35, 189, 224, 0.347);
            padding-left: 10px;
            padding-right: 10px;
            font-size: 14px;
            cursor: pointer;
        }
        .short-desc {
            font-size: 15px;
        }
        .button {
            display: flex;
            justify-content: end;
            text-decoration: none;
            margin-top: 10px;
        }
        .btnMore {
            color: rgba(0, 0, 0, 0.672);
            text-decoration: none;
            font-size: 14px;
            font-weight: bold;
            padding: 10px;
            background-color: rgba(35, 189, 224, 0.29);
            border-radius: 50px;
            border: 1px solid rgba(0, 0, 0, 0.11);
            transition: background .3s ease;
        }
        .btnMore:hover {
            background-color: rgb(255, 255, 255);
            border: 1px solid rgba(0, 0, 0, 0.147);
            cursor: pointer;
        }
    }
</style>