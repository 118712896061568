<template>
    <div class="container">
        <div class="postCard">
            <div class="postTitle">
                {{ post.ftitle }}
            </div>
            <div class="postSubtitle">
                {{ post.fsubtitle }}
            </div>
            <div class="postDescription">
                {{ post.fdesc }}
            </div>
            <div class="postDate">
                {{ date.hours }}:{{ date.minute }} {{ date.day }}.{{ date.month }}.{{ date.year }} tarihinde paylaşıldı.
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        name : 'PostDetail',
        data(){
            return {
                posts : [],
                post : {
                    ftitle : 'Yükleniyor...',
                    fsubtitle : 'Henüz bu adrese bir post girişi yapılmamış olabilir!',
                    fdesc : 'Sayfayı yenileyip tekrar deneyebilirsiniz. İnternet bağlantınızda bir problem olabilir. Ya da post silinmiş olabilir. Veya hiç yüklenmemiş vs.'
                },
                date : {
                    day : 24,
                    month : 10,
                    year : 2001,
                    hours : 12,
                    minute : 35
                }
            }
        },
        mounted(){
            const postId = this.$route.params.id;
            axios.get('posts.json')
                .then(response=>{
                    let data = response.data
                    this.posts = Object.values(data)
                    if(this.posts[postId]){
                        this.post = this.posts[postId]
                        this.date = this.post.fdate
                    }
                })
                .catch((e)=>{e})
        }

    }
</script>


<style scoped>
    @media only screen and (min-width: 715px){
        .container {
            min-height: 500px;
            width: 70%;
            margin-left: 15%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 50px;
            padding-bottom: 50px;
        }
        .postCard {
            background-color: rgba(240, 248, 255, 0.355);
            border: 1px solid rgba(0, 0, 0, 0.075);
            width: 80%;
            padding: 50px;
            border-radius: 10px;
        }
        .postTitle {
            padding: 20px;
            font-size: 30px;
            color: rgb(35, 189, 224);
            font-weight: bold;
            border-bottom: 1px solid rgba(0, 0, 0, 0.33);
        }
        .postSubtitle {
            padding: 10px;
            padding-left: 20px;
            font-weight: bold;
            font-size: 18px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.33);
        }
        .postDescription {
            padding: 20px;
            font-size: 15px;
            min-height: 100px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.33);
        }
        .postDate {
            display: flex;
            justify-content: end;
            font-size: 10px;
            color: rgba(0, 0, 0, 0.465);
            padding: 20px;
            font-weight: bold;
        }
    }
    @media only screen and (max-width: 715px){
        .container {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 500px;
            padding-top: 50px;
            padding-bottom: 50px;
        }
        .postCard {
            border: 1px solid rgba(0, 0, 0, 0.075);
            width: 70%;
            background-color: rgba(240, 248, 255, 0.355);
            border-radius: 10px;
            padding: 50px;
        }
        .postTitle {
            padding: 20px;
            font-size: 20px;
            color: rgb(35, 189, 224);
            font-weight: bold;
            border-bottom: 1px solid rgba(0, 0, 0, 0.33);
        }
        .postSubtitle {
            padding: 10px;
            padding-left: 20px;
            font-size: 14px;
            font-weight: bold;
            border-bottom: 1px solid rgba(0, 0, 0, 0.33);
        }
        .postDescription {
            padding: 20px;
            font-size: 12px;
            min-height: 100px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.33);
        }
        .postDate {
            display: flex;
            justify-content: end;
            font-size: 10px;
            color: rgba(0, 0, 0, 0.465);
            padding: 20px;
            font-weight: bold;
        }
    }
    
</style>