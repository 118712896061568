<template>
    <div class="top-future">
        <hr>
        <div class="futures">
            <router-link class="futures-title" :to="'/posts/' + index" >{{ post.ftitle }}</router-link>
            <div class="futures-subtitle">{{ post.fsubtitle }}</div>
             <div class="futures-description">{{ post.fdesc }}</div>
        </div>
        <div class="date">
            <div class="dateCard">
                {{ post.fdate.hours }}:{{ post.fdate.minute }} {{ post.fdate.day }}.{{ post.fdate.month }}.{{ post.fdate.year }} tarihinde yayınlandı
            </div>    
        </div>
    </div>
</template>

<script>
    export default {
        name : 'TopFuture',
        props : {
            post : Object,
            index : Object
        },
        data(){
            return {
                newIndex : ''
            }
        },
        methods : {
            
        },
        
    } 
</script>

<style scoped>
    @media only screen and (min-width: 630px){
        .top-future {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50%;
            margin-top: 40px;
            height: 50%;
            padding: 20px;
            border: 1px solid rgba(0, 0, 0, 0.081);
            background-color: rgba(240, 248, 255, 0.372);
            border-radius: 5px;
        }
        .top-img {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 5%;
        }
        img {
            height: 200px;
            border-radius: 20px;
            transition: height .5s ease;
        } 
        img:hover {
            height: 195px;
        }
        .titles {
            margin-left: 20px;
            font-size: 20px;
            font-weight: bold;
        }
        .futures {
            width: 100% - 40px;
            height: 90%;
            padding: 20px;
        }
        .futures-title {
            display: flex;
            width: 100%;
            height: 10%;
            align-items: center;
            font-size: 18px;
            font-weight: bold;
            text-decoration: none;
            color: rgb(60, 60, 60);
            transition: color 0.3s ease;
        }
        .futures-title:hover {
            color: rgba(35, 189, 224)
        }
        .futures-subtitle {
            width: 100%;
            font-size: 15px;
            margin-top: 5px;
        }
        .futures-description {
            margin-top: 15px;
            width: 100%;
            height: 80%;
            font-size: 12px;
        }
        .date {
            display: flex;
            justify-content: end;
            margin-right: 20px;
            font-size: 10px;
            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        }
        .dateCard {
            background-color: rgba(35, 189, 224, 0.478);
            padding: 5px;
            font-weight: bold;
            border-radius: 15px;
        }
    }
    @media only screen and (max-width: 630px){
    .top-future {
        width: 100%;
        margin-top: 40px;
        height: 50%;
    }
    .top-img {
        display: none;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    img {
        height: 100px;
        border-radius: 20px;
        transition: height .5s ease;
    } 
    img:hover {
        height: 195px;
    }
    .titles {
        display: flex;
        justify-content: start;
        padding: 20px;
        font-size: 20px;
        font-weight: bold;
    }
    .futures {
        max-width: 100%;
        padding: 20px;
    }
    .futures-title {
        display: flex;
        width: 100%;
        height: 10%;
        align-items: center;
        justify-content: start;
        font-size: 18px;
        font-weight: bold;
        text-decoration: none;
        color: rgb(60, 60, 60);
        transition: color 0.3s ease;
    }
    .futures-title:hover {
        color: rgba(35, 189, 224)
    }
    .futures-subtitle {
        display: flex;
        justify-content: start;
        align-items: center;
        width: 100%;
        font-size: 15px;
        margin-top: 5px;
    }
    .futures-description {
        display: flex;
        align-items: center;
        justify-content: start;
        margin-top: 15px;
        width: 100%;
        height: 80%;
        font-size: 12px;
    }
    .date {
        display: flex;
        align-items: center;
        justify-content: start;
        padding: 20px;
        font-size: 10px;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }
    .dateCard {
        background-color: rgba(35, 189, 224, 0.478);
        padding: 10px;
        font-weight: bold;
        border-radius: 15px;
    }
    }
</style>