<template>
    <div class="container">
        <div class="myCard">
            <div class="subcard">
                <div class="logo"><i class="fas fa-user"></i></div>
                <div class="info"> {{ myInfo.name }} </div>
            </div>
            <div class="subcard">
                <div class="logo">
                    <i class="fas fa-envelope"></i>
                </div>
                <div class="info">{{myInfo.mail}}</div>
            </div>
            <div class="subcard">
                <div class="logo">
                    <i class="fab fa-instagram"></i>
                </div>
                <div class="info">{{myInfo.insta}}</div>
            </div>
            <div class="subcard">
                <div class="logo">
                    <i class="fab fa-twitter"></i>
                </div>
                <div class="info">{{myInfo.twitter}}</div>
            </div>
            <div class="subcard">
                <div class="logo">
                    <i class="fab fa-linkedin"></i>
                </div>
                <div class="info">{{myInfo.linkedin}}</div>
            </div>
            <div class="subcard">
                <div class="logo">
                    <i class="fab fa-github"></i>
                </div>
                <div class="info">{{myInfo.github}}</div>
            </div>
            <div class="subcard">
                <div class="logo">
                    <i class="fab fa-discord"></i>
                </div>
                <div class="info">{{myInfo.discord}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name : 'ContactPage',
        data(){
            return {
                myInfo : {
                    name : 'Görkem Başaran',
                    tel : '+90 531 529 9154',
                    mail : 'info@gorkembasaran.com.tr',
                    insta : 'grkmbasaran',
                    twitter : 'savehkei',
                    linkedin : 'gorkembasaran1',
                    github : 'gorkembasaran',
                    discord : 'gorkembasaran'
                }
            }
        }
    }
</script>

<style scoped>
    @media only screen and (min-width: 838px){
        .container {
            display: flex;
            align-items: center;
            width: 100%;
            min-height: 600px;
        }
        .myCard {
            width: 50%;
            margin-left: 25%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .subcard {
            margin-top: 2px;
            padding: 15px;
            width: 50%;
            display: flex;
            justify-content: center;
            cursor: pointer;
        }
        .logo {
            font-size: 20px;
            padding: 10px;
            color: rgb(255, 255, 255);
            width: 15%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid rgba(0, 0, 0, 0.235);
            border-bottom-left-radius: 20px;
            border-top-left-radius: 20px;
            background-color: rgb(35, 189, 224);
        }
        .info {
            padding: 5px;
            font-size: 12pxpx;
            width: 85%;
            display: flex;
            align-items: center;
            border-bottom: 1px solid rgba(0, 0, 0, 0.235);
            border-right: 1px solid rgba(0, 0, 0, 0.235);
            border-top: 1px solid rgba(0, 0, 0, 0.235);
            background-color: white;
        }
    }
    @media only screen and (max-width: 838px){
        .container {
            width: 100%;
            height: 100%;
        }
        .myCard {
            width: 60%;
            margin-left: 20%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .subcard {
            margin-top: 2px;
            padding: 15px;
            width: 90%;
            display: flex;
            justify-content: center;
            cursor: pointer;
        }
        .logo {
            font-size: 20px;
            padding: 15px;
            color: rgb(255, 255, 255);
            width: 15%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid rgba(0, 0, 0, 0.165);
            border-bottom-left-radius: 20px;
            border-top-left-radius: 20px;
            background-color: rgb(35, 189, 224);
        }
        .info {
            padding: 5px;
            font-size: 15px;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.587);
            width: 85%;
            display: flex;
            align-items: center;
            border-bottom: 1px solid rgba(0, 0, 0, 0.165);
            border-top: 1px solid rgba(0, 0, 0, 0.165);
            background-color: white;
        }
    }
    @media only screen and (max-width: 540px){
        .container {
            width: 100%;
            height: 100%;
        }
        .myCard {
            width: 90%;
            margin-left: 5%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .subcard {
            margin-top: 2px;
            padding: 15px;
            width: 100%;
            display: flex;
            justify-content: center;
            cursor: pointer;
        }
        .logo {
            font-size: 20px;
            padding: 12px;
            color: rgb(255, 255, 255);
            width: 15%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid rgba(0, 0, 0, 0.235);
            border-bottom-left-radius: 20px;
            border-top-left-radius: 20px;
            background-color: rgb(35, 189, 224);
        }
        .info {
            padding: 5px;
            color: rgba(0, 0, 0, 0.587);
            font-size: 20px;
            font-weight: bold;
            width: 85%;
            display: flex;
            align-items: center;
            border-bottom: 1px solid rgba(0, 0, 0, 0.235);
            border-top: 1px solid rgba(0, 0, 0, 0.235);
            background-color: white;
        }
    }
    
    

</style>