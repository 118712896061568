<template>
    <div class="container">
            <post-box
            :postsArray="posts"
            ></post-box>
    </div>
</template>

<script>
    import axios from 'axios'
    import PostBox from './PostBox.vue'

    export default {
        name : 'PostPage',
        components : {
            PostBox : PostBox
        },
        data(){
            return {
                posts : [
                    {
                       ftitle : 'İçerik yükleniyor...',
                       fsubtitle : 'İçerik yükleniyor...',
                       fdesc : 'İçerik yükleniyor...',
                       fdate : {
                        hours : 12,
                        minute : 30,
                        day : 24,
                        month : 10,
                        year : 2001
                       }
                    },
                    {
                       ftitle : 'İçerik yükleniyor...',
                       fsubtitle : 'İçerik yükleniyor...',
                       fdesc : 'İçerik yükleniyor...',
                       fdate : {
                        hours : 12,
                        minute : 30,
                        day : 24,
                        month : 10,
                        year : 2001
                       }
                    },
                    {
                       ftitle : 'İçerik yükleniyor...',
                       fsubtitle : 'İçerik yükleniyor...',
                       fdesc : 'İçerik yükleniyor...',
                       fdate : {
                        hours : 12,
                        minute : 30,
                        day : 24,
                        month : 10,
                        year : 2001
                       }
                    }
                ]
            }
        },
        created(){
            axios.get('posts.json')
            .then((response) => {
                let array = Object.values(response.data)
                this.posts = array.reverse()
            })
            .catch((e)=>{e})
        }
    }
</script>


<style scoped>
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    
</style>