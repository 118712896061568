<template>
    <div class="container">
        <div class="onr">
            <top-future 
            :post="postFuture"
            :index="index"
            ></top-future>
        </div>
        <div class="game">
            <router-link class="titles" to="/dictionary/game">go to Dictionary Game! </router-link>
        </div>
    </div>
</template>

<script>
    import TopFuture from './TopFuture.vue';
    import axios from 'axios'

    export default {
        name : 'HomePage',
        components : {
            TopFuture : TopFuture,
        },
        data(){
            return {
                postFuture : {
                    title:"Öne Çıkan Gönderi:",
                    ftitle:"Henüz herhangi bir yazı öne çıkarılmamış!",
                    fsubtitle:"Konu belirlenmemiş",
                    fdesc:"İnternet bağlantınız kopmuş olabilir.Lütfen kontrol edip tekrar deneyiniz.",
                    fdate: {
                        day : 24,
                        month : 10,
                        year : 2001,
                        hours : 12,
                        minute : 35
                    }
                },
                index : ''
            }
        },
        created(){
            axios.get("posts.json")
                .then((response)=> {
                    let data = response.data
                    this.posts = data
                    for(let key in data){
                        if(data[key].future === true){
                            this.index = key
                            this.postFuture = {...data[key], title : "Öne Çıkan Gönderi:"}
                        }
                    }
                    this.postFuture.fdate = this.postFuture.fdate.split('T')[0]
                })
                .catch((e)=>{e})
            }
    }
</script>

<style scoped>
    @media only screen and (min-width: 630px){
        .container {
            display: flex;
            flex-direction: column;
            width: 100%;
            min-height: 600px;
            justify-content: center;
            align-items: center;
        }
        .onr {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-bottom: 50px;
        }
        .game {
            border: 1px solid rgba(0, 0, 0, 0.081);
            width: 50%;
            border-radius: 5px;
            background-color: rgba(240, 248, 255, 0.372);
        }
        .titles {
            display: flex;
            justify-content: center;
            padding: 20px;
            text-decoration: none;
            color: rgba(4, 163, 163, 0.871);
            border-radius: 5px;
            background-color: rgba(214, 230, 230, 0.709);
            font-weight: bold;
            font-size: 18px;
            transition: ease .5s background;
        }
        .titles:hover {
            color : rgb(214, 230, 230);
            background-color: rgba(4, 163, 163, 0.871);
        }
    }
    @media only screen and (max-width: 630px){
        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            display: flex;        
            min-height: 550px;
        }
        .onr {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-bottom: 50px;
            border-bottom: 2px solid rgba(0, 0, 0, 0.129);
        }
        .game {
            border-bottom: 1px solid rgba(0, 0, 0, 0.081);
            width: 50%;
            border-radius: 5px;
        }
        .titles {
            display: flex;
            justify-content: center;
            padding: 20px;
            text-decoration: none;
            color: rgba(4, 163, 163, 0.871);
            border-radius: 5px;
            background-color: rgba(214, 230, 230, 0.709);
            font-weight: bold;
            font-size: 18px;
            transition: ease .5s background;
        }
        .titles:hover {
            color : rgb(214, 230, 230);
            background-color: rgba(4, 163, 163, 0.871);
        }
    }
</style>