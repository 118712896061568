import { createRouter, createWebHistory } from "vue-router";
import HomePage from '../components/HomePage.vue';
import AboutPage from '../components/AboutPage.vue';
import ContactPage from '../components/ContactPage.vue';
import ProjectsPage from '../components/ProjectsPage.vue';
import PostPage from '../components/PostPage.vue'
import CreatePost from '../components/CreatePost.vue';
import CreateProject from '../components/CreateProject.vue';
import AdminPanel from '../components/admin/AdminPanel.vue';
import PostDetail from '../components/PostDetail.vue';
import DictionaryGame from '../components/DictionaryGame.vue';
import ProjectDetail from '../components/ProjectDetail.vue';
import DictionaryPage from '../components/DictionaryPage.vue';
import CreateSubject from '../components/notes/CreateSubject.vue';
import SubjectsPage from '../components/notes/SubjectPage';
import SubjectDetail from '../components/notes/SubjectDetail'
import LoginPage from '../components/LoginPage.vue'
import RegisterPage from '../components/RegisterPage.vue'
import IrregularWords from '../components/IrregularWords.vue'



const routes = [
    {
        path : '/',
        name : 'homePage',
        component : HomePage
    },
    {
        path : '/about',
        name : 'AboutPage',
        component : AboutPage
    },
    {
        path : '/contact',
        name : 'ContactPage',
        component : ContactPage
    },
    {
        path : '/projects',
        name : 'ProjectsPage',
        component : ProjectsPage
    },
    {
        path : '/projects/:id',
        name : 'ProjectDetail',
        component : ProjectDetail,
    },
    {
        path : '/posts',
        name : 'PostPage',
        component : PostPage,
    },
    {
        path : '/posts/:id',
        name : 'PostDetail',
        component : PostDetail,
    },
    {
        path : '/subjects',
        name : 'SubjectPage',
        component : SubjectsPage,
    },
    {
        path : '/subjects/:id',
        name : 'SubjectDetail',
        component : SubjectDetail,
    },
    {
        path : '/create/post',
        name : 'createPost',
        component : CreatePost
    },
    {
        path : '/create/project',
        name : 'createProject',
        component : CreateProject
    },
    {
        path : '/admin',
        name : 'adminPanel',
        component : AdminPanel
    },
    {
        path : '/dictionary',
        name : 'DictionaryPage',
        component : DictionaryPage
    },
    {
        path : '/create/subject',
        name : 'CreateSubject',
        component : CreateSubject
    },
    {
        path : '/dictionary/game',
        name : 'DictionaryGame',
        component : DictionaryGame
    },
    {
        path : '/login',
        name : 'LoginPage',
        component : LoginPage
    },
    {
        path : '/register',
        name : 'RegisterPage',
        component : RegisterPage
    },
    {
        path : '/irregulars',
        name : 'IrregularWords',
        component : IrregularWords
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router;