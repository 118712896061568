import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { pinia } from './store';
import axios from 'axios';

axios.defaults.baseURL = "https://my-blog-c925a-default-rtdb.firebaseio.com/"


createApp(App).use(pinia).use(router).mount('#app')

