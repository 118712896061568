<template>
    <div class="profil p-20">
                <div class="my-photos">
                    <img :src="img" alt="">
                </div>
                <div class="my-infos">
                    <div class="names mt">Görkem Başaran</div>
                    <div class="education mt">Alanya Alaaddin Keykubat Üniversitesi</div>
                    <div class="department mt">Bilgisayar Mühendisliği(İngilizce)</div>
                </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name : 'ProfilCard',
        data(){
            return {
                img : ''
            }
        },
        created(){
            axios.get('img.json')
                .then((response)=>{
                    this.img = response.data.img
                })
                .catch((e)=>{e})
        }
    }

</script>

<style scoped>
    @media only screen and (min-width: 815px) {
        .profil {
            height: 300px;
            display: flex;
        }
        .my-photos {
            width: 30%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        img {
            height: 100%;
            border-radius: 1000px;
        }
        img:hover {
            border: 5px solid rgba(35, 189, 224, 0.28);
        }
        .my-infos {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: 30px;
            width: 70%;
        }
        .mt {
            margin-top: 5px;
        }
        .names {
            font-size: 30px;
            font-weight: bold;
        }
        .education {
            font-size: 20px
        }
        .department {
            font-size: 15px;
        }
        .p-20 {
            padding: 20px;
        }
    }
    @media only screen and (max-width: 815px) {
        .profil {
            height: 300px;
            display: flex;
            justify-content: center;
        }
        .my-photos {
            width: 30%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        img {
            height: 100%;
            border-radius: 1000px;
        }
        img:hover {
            border: 5px solid rgba(35, 189, 224, 0.478);
        }
        .my-infos {
            display: none;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: 30px;
            width: 70%;
        }
        .mt {
            margin-top: 5px;
        }
        .names {
            font-size: 30px;
            font-weight: bold;
        }
        .education {
            font-size: 20px
        }
        .department {
            font-size: 15px;
        }
        .p-20 {
            padding: 20px;
        }
    }
</style> 