<template>
    <div v-if="this.tokenId" class="container">
        <div class="card">
            <div class="pageTitle">
                PROJE EKLE
            </div>
        </div>
        <div class="card">
            <label>Projenin Başlığı:</label>
            <input v-model="project.title" type="text">
        </div>
        <div class="card">
            <label>Kullanılan Diller:</label>
            <input v-model="project.section" type="text">
        </div>
        <div class="card">
            <label>Projenin Açıklaması:</label>
            <textarea v-model="project.description"></textarea>
        </div>
        <div class="card">
            <label>Projenin Github Bağlantısı</label>
            <input v-model="project.link">
        </div>
        <div class="cardBtn">
            <button @click="saveproject">Kaydet</button>
        </div>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name : 'CreateProject',
        data() {
            return {
                project : {
                    title : '',
                    section : '',
                    description : '',
                    link : ''
                },
                tokenId : null
            }
        },
        methods : {
            saveproject(){
                const languages = this.project.section.split(',')
                axios.post("projects.json",{...this.project , languages})
                    .then((response) => {
                        console.log(response)
                        this.project = {
                            title : '',
                            section : '',
                            description : '',
                            link : ''
                        }
                        window.location.href = '/admin'
                    })
                    .catch(e => console.log(e))
            }
        },
        created(){
            axios.get('token.json')
                .then(response=>{
                    this.tokenId = response.data.value
                    if(!this.tokenId){
                        window.location.href = '/admin'
                    }
                })
                .catch((e)=>{e})
        }
    }
</script>

<style scoped>
    .container {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    .card{
        width: 80%;
        display: flex;
        padding: 25px;
        justify-content: center;
        align-items: center;
    }
    .cardBtn{
        width: 80%;
        display: flex;
        padding: 25px;
        justify-content: end;
    }
    label {
        width: 30%;
    }
    input {
        font-size: 15px;
        padding: 10px;
        width: 70%;
    }
    textarea {
        width: 70%;
        height: 300px;
    }
    button {
        width: 15%;
        margin-right: 20px;
        padding: 10px;
        font-size: 15px;
        font-weight: bold;
        border-radius: 10px;
        background-color: rgba(35, 224, 151, 0.738);
        border: 1px solid rgba(35, 224, 151, 0.216);
        cursor: pointer;
    }
    button:hover {
        background-color: rgba(35, 224, 151, 0.558);
    }
    .pageTitle {
        font-size: 20px;
        font-weight: bold;
    }

</style>