<template>
    <div class="pre p-20">
                <div class="pre-desc">{{ pre }}</div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name : 'PreWrite',
        data(){
            return {
                pre : ''
            }
        },
        created(){
            axios.get('pre.json')
                .then((response)=>{
                    this.pre = response.data.pre
                })
                .catch((e)=>{e})
        }
    }
</script>

<style scoped>
    .pre-desc {
        background-color: rgba(255, 255, 255, 0.919);
        border-radius: 10px;
        padding: 20px;
        font-size: 15px;
    }
    .p-20 {
        padding: 20px;
    }
</style>