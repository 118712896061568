<template>
    <div v-if="this.tokenId" class="container">
        <div class="card">
            <div class="pageTitle">
                POST EKLE
            </div>
        </div>
        <div class="card">
            <label>Başlık</label>
            <input v-model="post.ftitle" type="text">
        </div>
        <div class="card">
            <label>Konu</label>
            <input v-model="post.fsubtitle" type="text">
        </div>
        <div class="card">
            <label>Açıklama</label>
            <textarea v-model="post.fdesc"></textarea>
        </div>
        <div class="cardBtn">
            <button @click="savePost">Kaydet</button>
        </div>
    </div>
    
</template>

<script>
    import axios from "axios";

    export default {
        name : 'CreatePost',
        data() {
            return {
                post : {
                    ftitle:"",
                    fsubtitle:"",
                    fdesc:"",
                    fdate:"",
                    future: false
                },
                tokenId : ''
            }
        },
        methods : {
            savePost(){
                axios.post("posts.json",{...this.post , fdate : {
                    day : new Date().getDate(),
                    month : new Date().getMonth() + 1,
                    year : new Date().getFullYear(),
                    hours : new Date().getHours(),
                    minute : new Date().getMinutes()
                }})
                    .then((response) => {
                        console.log(response)
                        window.location.href = '/admin'
                    })
                    .catch(e => console.log(e))
            },
        },
        created(){
            axios.get('token.json')
                .then(response=>{
                    this.tokenId = response.data.value
                    if(!this.tokenId){
                        window.location.href = '/admin'
                    }
                })
                .catch((e)=>{e})
        }
    }
</script>

<style scoped>
    .container {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    .card{
        width: 80%;
        display: flex;
        padding: 25px;
        justify-content: center;
        align-items: center;
    }
    .cardBtn{
        width: 80%;
        display: flex;
        padding: 25px;
        justify-content: end;
    }
    label {
        width: 30%;
    }
    input {
        font-size: 15px;
        padding: 10px;
        width: 70%;
    }
    textarea {
        width: 70%;
        height: 300px;
    }
    button {
        width: 15%;
        margin-right: 20px;
        padding: 10px;
        font-size: 15px;
        font-weight: bold;
        border-radius: 10px;
        background-color: rgba(35, 224, 151, 0.738);
        border: 1px solid rgba(35, 224, 151, 0.216);
        cursor: pointer;
    }
    button:hover {
        background-color: rgba(35, 224, 151, 0.558);
    }
    .pageTitle {
        font-size: 20px;
        font-weight: bold;
    }

</style>