<template>
    <div class="container">
        <div class="cards">
            <div class="titles">Profil</div>
            <hr>
            <profil-card></profil-card>
        </div>
        <cards-page
            :preWriteData="PreWrite"
        ></cards-page>
        <div class="cards">
            <div class="titles">Önyazı</div>
            <hr>
            <pre-write></pre-write>
        </div>
        <div class="cards">
            <div class="cards-mid">
            <div class="cards-double">
                <div class="titles">Yetenekler</div>
                <sections-prop
                    :items="skills"
                ></sections-prop>
            </div>
            <div class="cards-double">
                <div class="titles">Tamamlanan Kurslar</div>
                <sections-prop
                    :items="courses"
                ></sections-prop>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PreWrite from './PreWrite.vue';
    import SectionsProp from './SectionsProp.vue';
    import ProfilCard from './ProfilCard.vue';
    import axios from 'axios'

    export default {
        name : 'AboutPage',
        components : {
            PreWrite : PreWrite,
            SectionsProp : SectionsProp,
            ProfilCard : ProfilCard
        },
        data(){
            return {
                skills : [],
                courses : [
                    
                ]
            }
        },
        created(){
            axios.get('skills.json')
                .then((response)=>{
                    let data = response.data
                    this.skills = Object.values(data)
                })
            axios.get('courses.json')
                .then((response)=>{
                    let data = response.data
                    this.courses = Object.values(data)
                })
        }
    }
</script>

<style scoped>
    @media only screen and (min-width: 890px) {
        .container {
            width: 100%;
            margin-top: 20px;
        }
        .cards {
            width: 80%;
            margin-left: 10%;
            margin-top: 50px;
        }
        .cards-mid {
            display: flex;
            width: 90%;
            margin-left: 5%;
        }
        .cards-double {
            width: 50%;
        }

        .titles {
            display: flex;
            justify-content: center;
            font-size: 30px;
            font-weight: bold;
        }
    }
    @media only screen and (max-width: 890px) {
        .container {
        width: 100%;
        margin-top: 20px;
        }
        .cards {
            width: 80%;
            margin-left: 10%;
            margin-top: 50px;
        }
        .cards-mid {
            display: flex;
            width: 90%;
            margin-left: 5%;
        }
        .cards-double {
            width: 50%;
        }

        .titles {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 15px;
            font-weight: bold;
        }
    }
    
    
</style>