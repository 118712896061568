<template>
    <div class="body">
        <div class="title">Kayıt Ol!</div>
        <div class="regBox">
            <div class="idBox">
                <input class="regInput" placeholder="Ad" type="text">
            </div>
            <div class="idBox">
                <input class="regInput" placeholder="Soyad" type="text">
            </div>
            <div class="idBox">
                <input class="regInput" placeholder="Mail" type="text">
            </div>
            <div class="idBox">
                <input class="regInput" placeholder="Kullanıcı adı" type="text">
            </div>
            <div class="idBox">
                <input class="regInput" placeholder="Şifre" type="password">
            </div>
            <div class="idBox">
                <input class="regInput" placeholder="Tekrar Şifre" type="password">
            </div>
            <div class="idBox">
                <button class="regBtn">Kayıt Ol</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name : 'RegisterPage',
        data(){
            
        }
    }
</script>

<style scoped> 
    .body {
        display: flex;
        flex-direction: column;
        min-height: 720px;
        align-items: center;
        justify-content: center;
    }
    .title {
        margin: 20px;
        padding-left: 20px;
        padding-right: 20px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 25px;
        height: 50px;
        color: rgba(0, 0, 0, 0.739);
    }
    .regBox {
        background-color: rgba(35, 189, 224, 0.159);
        padding: 150px;
        border-radius: 20px;
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .idBox {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 5px;
        width: 50%;
    }
    .regInput {
        padding: 8px;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.458);
    }
    .regBtn {
        background-color: rgba(127, 255, 212, 0.427);
        color: rgba(0, 0, 0, 0.651);
        font-weight: bold;
        width: 90px;
        padding: 8px;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.458);
        transition: background 0.6s ease;
    }
    .regBtn:hover {
        background-color: rgba(127, 255, 212, 0.8);
        cursor: pointer;
    }
</style>