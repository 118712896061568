<template>
    <div class="container">
        <div class="projectCard">
            <div class="projectTitle">{{ project.title }}</div>
            <div class="projectLang" >
                <div class="languages" :key="language" v-for="language in languages">{{ language }}</div>
            </div>
            <div class="projectDesc">{{ project.description }}</div>
            <div class="projectLink">
                <a target="_blank" :href="project.link">
                    Kaynak Kodu
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        name : 'ProjectDetail',
        data(){
            return {
                projects : [],
                project : {
                    title : 'Yükleniyor...',
                    description : 'Henüz bu adrese bir proje girişi yapılmamış olabilir!',
                },
                languages : ['HTML','CSS','Javascript','Vue.js']
            }
        },
        mounted(){
            const projectId = this.$route.params.id;
            axios.get('projects.json')
                .then(response=>{
                    let data = response.data
                    this.projects = Object.values(data)
                    if(this.projects[projectId]){
                        this.project = this.projects[projectId]
                        this.languages = this.project.languages
                    }
                })
                .catch((e)=>{e})
        }
    }
</script>


<style scoped>
    @media only screen and (min-width: 570px){
        .container {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .projectCard {
            margin-top: 50px;
            width: 70%;
            min-height: 550px;
            display: flex;
            flex-direction: column;
        }
        .projectTitle {
            font-size: 30px;
            font-weight: bold;
            padding: 20px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.308);
        }
        .projectLang {
            display: flex;
            flex-wrap: wrap;
            padding: 10px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.308);
        }
        .languages {
            font-size: 12px;
            font-weight: bold;
            padding: 10px;
            background-color: rgba(240, 248, 255, 0.346);
            border: 1px solid rgba(0, 0, 0, 0.128);
            transition: background .5s ease;
            margin: 5px;
            border-radius: 5px;
        }
        .languages:hover {
            background-color: rgba(35, 189, 224, 0.183);
            cursor: pointer;
        } 
        .projectDesc {
            padding: 20px;
            font-size: 15px;
        }
        .projectLink {
            display: flex;
            justify-content: end;
            padding: 20px;
        }
        a {
            text-decoration: none;
            font-size: 12px;
            font-weight: bold;
            background-color: rgba(240, 248, 255, 0.346);
            padding: 10px;
            border-radius: 5px;
            color: rgba(0, 0, 0, 0.728);
            transition: background .5s ease;
            border: 1px solid rgba(0, 0, 0, 0.103);
        }
        a:hover {
            background-color: rgba(35, 189, 224, 0.183);
        }
    }
    @media only screen and (max-width: 570px){
        .container {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .projectCard {
            width: 90%;
            min-height: 550px;
            margin-top: 50px;
            display: flex;
            flex-direction: column;
        }
        .projectTitle {
            font-size: 24px;
            font-weight: bold;
            padding: 20px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.308);
        }
        .projectLang {
            display: flex;
            flex-wrap: wrap;
            padding: 5px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.308);
        }
        .languages {
            font-size: 12px;
            font-weight: bold;
            padding: 8px;
            background-color: rgba(240, 248, 255, 0.346);
            border: 1px solid rgba(0, 0, 0, 0.118);
            transition: background .5s ease;
            margin: 5px;
            border-radius: 5px;
        }
        .languages:hover {
            background-color: rgba(35, 189, 224, 0.183);
            cursor: pointer;
        } 
        .projectDesc {
            padding: 20px;
            font-size: 14px;
        }
        .projectLink {
            display: flex;
            justify-content: end;
            padding: 20px;
        }
        a {
            text-decoration: none;
            font-size: 12px;
            font-weight: bold;
            background-color: rgba(240, 248, 255, 0.346);
            padding: 10px;
            border-radius: 5px;
            transition: background .5s ease;
            color: rgba(0, 0, 0, 0.728);
            border: 1px solid rgba(0, 0, 0, 0.329);
        }
        a:hover {
            background-color: rgba(35, 189, 224, 0.183);
        }
    }

    


</style>