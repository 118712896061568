<template>
    <div v-for="post in postsArray" :key="post" class="top-future">
            <div class="futures">
                <router-link class="futures-title" :to="'/posts/' + newIndex" @mouseover="sendIndex(post)">{{ post.ftitle }}</router-link>
                <div class="futures-subtitle">{{ post.fsubtitle }}</div>
                <div class="futures-description">{{ post.fdesc }}</div>
            </div>
            <div class="date">
                <div class="dateCard">
                    {{ post.fdate.hours }}:{{ post.fdate.minute }} {{ post.fdate.day }}.{{ post.fdate.month }}.{{ post.fdate.year }} tarihinde yayınlandı
                </div>    
            </div>
    </div>
</template>

<script>
    export default {
        name : 'PostBox',
        props: {
            postsArray : Array,
        },
        data(){
            return {
                newIndex : '',
                newArray : ''
            }
        },
        methods:{
            sendIndex(post){
                let arr = Object.values(this.postsArray)
                let postReverse = arr.reverse()
                this.newIndex = postReverse.indexOf(post)
            }
        }

    }
</script>

<style scoped>
    @media only screen and (min-width: 500px){
        .top-future {
            width: 60%;
            margin-top: 40px;
            background-color: rgba(240, 248, 255, 0.372);
            padding: 40px;
            border-radius: 10px;
            border: 1px solid rgba(0, 0, 0, 0.122);
            margin-left: 20px;
        }
        .titles {
            margin-left: 20px;
            font-size: 20px;
            font-weight: bold;
        }
        .futures {
            width: 100% - 40px;
            height: 90%;
            padding: 20px;
        }
        .futures-title {
            display: flex;
            width: 100%;
            height: 10%;
            align-items: center;
            font-size: 25px;
            font-weight: bold;
            text-decoration: none;
            color: rgb(35, 189, 224);
            transition: color 0.3s ease;
        }
        .futures-title:hover {
            color: rgb(29, 162, 192)
        }
        .futures-subtitle {
            width: 100%;
            font-size: 20px;
            font-weight: bold;
            margin-top: 5px;
        }
        .futures-description {
            margin-top: 15px;
            width: 100%;
            height: 80%;
            font-size: 15px;
        }
        .date {
            display: flex;
            justify-content: end;
            margin-right: 20px;
            font-size: 10px;
            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        }
        .dateCard {
            background-color: rgba(35, 189, 224, 0.478);
            padding: 5px;
            font-weight: bold;
            border-radius: 15px;
        }
    }
    @media only screen and (max-width: 500px){
        .top-future {
            width: 80%;
            margin-top: 40px;
            height: 50%;
            background-color: rgba(240, 248, 255, 0.372);
            padding: 20px;
            border-radius: 10px;
            border: 1px solid rgba(0, 0, 0, 0.128);
        }
        .titles {
            margin-left: 20px;
            font-size: 20px;
            font-weight: bold;
        }
        .futures {
            width: 100% - 40px;
            height: 90%;
            padding: 20px;
        }
        .futures-title {
            display: flex;
            width: 100%;
            height: 10%;
            align-items: center;
            font-size: 18px;
            font-weight: bold;
            text-decoration: none;
            color: rgb(35, 189, 224);
            transition: color 0.3s ease;
        }
        .futures-title:hover {
            color: rgb(29, 162, 192)
        }
        .futures-subtitle {
            width: 100%;
            font-size: 15px;
            margin-top: 5px;
        }
        .futures-description {
            margin-top: 15px;
            width: 100%;
            height: 80%;
            font-size: 12px;
        }
        .date {
            display: flex;
            justify-content: end;
            margin-right: 20px;
            font-size: 10px;
            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        }
        .dateCard {
            background-color: rgba(35, 189, 224, 0.478);
            padding: 5px;
            font-weight: bold;
            border-radius: 15px;
        }
    }
    
</style>