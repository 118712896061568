<template>
    <div class="bottom">
        <div class="links">
            <a class="link" target="_blank" href="http://github.com/gorkembasaran">
                <i class="fab fa-github"></i>
            </a>
            <a class="link" target="_blank" href="https://www.linkedin.com/in/gorkembasaran1/">
                <i class="fab fa-linkedin"></i>
            </a>
            <a class="link" target="_blank" href="http://twitter.com/savehkei">
                <i class="fab fa-twitter"></i>
            </a>
            <a class="link" target="_blank" href="http://instagram.com/grkmbasaran">
                <i class="fab fa-instagram"></i>
            </a>
            <a class="link" href="mailto:grkmbasaran24@gmail.com">
                <i class="fas fa-envelope"></i>
            </a>
            <a class="link" target="_blank" href="https://discord.gg/jRsQdFda">
                <i class="fab fa-discord"></i>
            </a>
        </div>
    </div>
</template>


<script>

    export default {
        name : 'BottomPage',
    }
</script>


<style scoped>
    .bottom {
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100px;
        background: linear-gradient(to top, rgba(35, 189, 224, 0.478), rgba(228, 227, 227, 0));
        justify-content: center;
        text-align: center;
    }
    .links {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .link {
        font-size: 25px;
        margin-left: 15px;
        text-decoration: none;
        color: rgb(60, 60, 60);
        transition: font-size 0.4s ease;
    }
    .link:hover {
        color: rgba(255, 255, 255, 0.757);
        font-size: 35px;
    }
</style>