<template>
  <div class="container">
    <header-app></header-app>
    <router-view />
    <bottom-page></bottom-page>
  </div>
</template>

<script>
  import HeaderApp from './components/Header.vue'
  import BottomPage from './components/BottomPage.vue';

  export default {
    name: 'App',
    components : {
      HeaderApp : HeaderApp,
      BottomPage : BottomPage
    },
  }

</script>

<style>
  body {
    margin: 0;
    background-color: rgba(228, 227, 227, 0.447);
    color: rgb(60, 60, 60);
    font-family: system-ui, -apple-system, /* Firefox supports this but not yet `system-ui` */ 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';;
  }

</style>
