<template>
    <div class="container">
        <div class="edit">
            <div class="section">
                <input @keyup.enter="addWords" v-model="kelime.ingilizcev1" placeholder="İngilizce v1" type="text">
                <input @keyup.enter="addWords" v-model="kelime.ingilizcev2" placeholder="İngilizce v2" type="text">
                <input @keyup.enter="addWords" v-model="kelime.ingilizcev3" placeholder="İngilizce v3" type="text">
                <input @keyup.enter="addWords" v-model="kelime.turkce" placeholder="Türkçe" type="text">
                <button @click="addWords" class="sectionBtn">Ekle</button>
            </div>
        </div>
        <div class="kelimeler">
            <div v-for="word in bulunanKelimeler" :key="word" class="kelime">
                <div class="ing">{{ word.ingilizce }}</div>
                <div class="tur">{{ word.turkce }}</div>
            </div>
        </div>
        <hr>
        <div class="kelimeler">
            <div v-for="word in kelimeler" :key="word" class="kelime">
                <div class="tur">{{ word.ingilizcev1 }}</div>
                <div class="tur">{{ word.ingilizcev2 }}</div>
                <div class="tur">{{ word.ingilizcev3 }}</div>
                <div class="tur">{{ word.turkce }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name : 'IrregularWords',
        data(){
            return {
                kelime : {
                    turkce : '',
                    ingilizcev1 : '',
                    ingilizcev2 : '',
                    ingilizcev3 : ''
                },
                searchKelime : '',
                kelimeler : [],
                bulunanKelimeler : []
            }
        },
        methods : {
            addWords(){
                if(this.kelime.turkce !== '' && this.kelime.ingilizce !== ''){
                    axios.post('irregular_words.json', { ...this.kelime })
                    .then((response)=>{
                        console.log(response)
                        this.kelime = {
                            turkce : '',
                            ingilizcev1 : '',
                            ingilizcev2 : '',
                            ingilizcev3 : ''
                        }
                        axios.get('irregular_words.json')
                            .then((response)=>{
                                this.kelimeler = Object.values(response.data)
                            })
                            .catch((e)=>{e})
                    })
                }else{
                    alert('lütfen önce gerekli iki alanı doldurunuz!')
                }
            },
            searchWord(){
                if(this.searchKelime !== ''){
                    this.bulunanKelimeler = []
                    let words = this.kelimeler
                    let length = words.length
                    for(let i=0 ; i<length ; i++){
                        if(this.searchKelime.toLowerCase() === this.kelimeler[i].turkce || this.searchKelime.toLowerCase() === this.kelimeler[i].ingilizce ){
                            this.bulunanKelimeler.push(this.kelimeler[i])
                        }
                    }
                }else{
                    alert('Lütfen önce bir kelime giriniz!')
                }
            }
        },
        created(){
            axios.get('irregular_words.json')
                .then((response)=>{
                    this.kelimeler = Object.values(response.data)
                })
                .catch((e)=>{e})
        }

    }
</script>

<style scoped>
    @media only screen and (min-width: 500px){
        .container{
            width: 100%;
            min-height: 700px;
        }
        .kelimeler {
            display: flex;
            flex-wrap: wrap;
            width: 80%;
            margin-left: 10%;
        }
        .kelime{
            margin: 7px;
            padding: 10px;
            border: 1px solid rgba(46, 47, 41, 0.397);
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 15%;
            font-size: 12px;
            font-weight: bold;
            transition: background .5s ease;
        }
        .kelime:hover{
            cursor: pointer;
            background-color: rgba(2, 255, 141, 0.233);
        }
        .ing{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px;
            width: 50%;
            border-right: 1px solid;
        }
        .tur{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px;
            width: 50%;
        }
        .edit {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .section{
            display: flex;
            padding: 10px;
        }
        input {
            padding: 5px;
            width: 65%;
        }
        .sectionBtn{
            padding: 5px;
            color: rgb(255, 255, 255);
            width: 25%;
            transition: background .5s ease;
            border-radius: 5px;
            border: 1px solid rgba(0, 0, 0, 0.237);
            background-color: rgba(35, 189, 224, 0.478);
        }
        .sectionBtn:hover {
            cursor: pointer;
            background-color: rgba(35, 189, 224, 0.69);
        }
    }
    @media only screen and (max-width: 500px){
        .container{
            width: 100%;
            min-height: 700px;
        }
        .kelimeler {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            width: 80%;
            margin-left: 10%;
        }
        .kelime{
            padding: 20px;
            border: 1px solid rgba(46, 47, 41, 0.397);
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 70%;
            font-size: 20px;
            transition: background .5s ease;
        }
        .kelime:hover{
            cursor: pointer;
            background-color: rgba(2, 255, 141, 0.233);
        }
        .ing{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px;
            width: 50%;
            border-right: 1px solid;
        }
        .tur{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px;
            width: 50%;
        }
        .edit {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .section{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px;
            margin-top: 50px;
        }
        input {
            padding: 10px;
            font-size: 20px;
            width: 100%;
            margin: 5px;
        }
        .sectionBtn{
            padding: 10px;
            margin-top: 10px;
            color: rgb(255, 255, 255);
            font-weight: bold;
            width: 40%;
            min-height: 40px;
            transition: background .5s ease;
            border-radius: 20px;
            border: 1px solid rgba(0, 0, 0, 0.237);
            background-color: rgba(35, 189, 224, 0.478);
        }
        .sectionBtn:hover {
            cursor: pointer;
            background-color: rgba(35, 189, 224, 0.69);
        }
    }
</style>