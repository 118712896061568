<template>
    <div class="skills p-20">
        <div v-for="item in items" class="languages" :key="item">{{ item }}</div>
    </div>
</template>

<script>
    export default {
        name : 'SectionsProp',
        props : {
            items: Array
        }
    }
</script>

<style scoped>
    @media only screen and (min-width: 630px){
        .p-20 {
        padding: 20px;
        }
        .skills {
            display: flex;
            flex-wrap: wrap;
            font-weight: bold;
        }
        .languages {
            flex: 1;
            margin-left: 2px;
            margin-top: 2px;
            display: flex;
            justify-content: center;
            font-size: 12px;
            align-items: center;
            padding: 10px;
            border: 1px solid rgba(0, 0, 0, 0.165);
            background-color: rgba(255, 255, 255, 0.919);
            border-radius: 5px;
            transition: background .5s ease;
            cursor: pointer;
        }
        .languages:hover {
            background-color: rgba(35, 189, 224, 0.132);
        }
    }
    @media only screen and (max-width: 630px){
        .p-20 {
            padding: 20px;
        }
        .skills {
            display: flex;
            flex-wrap: wrap;
            font-weight: bold;
        }
        .languages {
            flex: 1;
            margin-left: 1px;
            margin-top: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            border: 1px solid rgba(0, 0, 0, 0.165);
            transition: background .5s ease;
            background-color: rgba(255, 255, 255, 0.919);
            border-radius: 5px;
            cursor: pointer;
        }
        .languages:hover {
            background-color: rgba(35, 189, 224, 0.132);
        }
    }
</style>