<template>
    <div class="header">
        <div class="logo">Görkem Başaran</div>
        <div class="menuControl-min">
            <i @click="displayBar" class="fas fa-bars"></i>
        </div>
        <div class="menuControl-max">
            <router-link class="titles" to="/">Anasayfa</router-link>
            <router-link class="titles" to="/posts">Gönderiler</router-link>
            <router-link class="titles" to="/projects">Projelerim</router-link>
            <router-link class="titles" to="/about">Hakkımda</router-link>
            <router-link class="titles" to="/contact">İletişim</router-link>
        </div>
    </div>
    <div class="menuControl none" id="display">
            <router-link class="titles border-right" to="/">Anasayfa</router-link>
            <router-link class="titles border-right" to="/posts">Gönderiler</router-link>
            <router-link class="titles border-right" to="/projects">Projelerim</router-link>
            <router-link class="titles border-right" to="/about">Hakkımda</router-link>
            <router-link class="titles border-right" to="/contact">İletişim</router-link>
    </div>
</template>

<script>
    export default {
        name : 'headerApp',
        methods : {
            displayBar() {
                const displayStyle = window.getComputedStyle(document.querySelector('#display')).display;
                
                if (displayStyle === 'none') {
                    document.querySelector('.menuControl').classList.remove('none');
                } else {
                    document.querySelector('.menuControl').classList.add('none');
                }
            }
        }
    }
</script>

<style scoped>
    @media only screen and (min-width: 630px) {
        body {
        width: 100%;
        }
        .divider {
            border-right: 2px solid rgba(0, 0, 0, 0.116);
            border-left: 2px solid rgba(0, 0, 0, 0.116);
        }
        .header {
            width: 100%;
            background: linear-gradient(to bottom, rgba(35, 189, 224, 0.478), rgba(226, 24, 24, 0));
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
        }
        .logo {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30%;
            font-size: 30px;
            font-family: 'Courier New', Courier, monospace;
        }
        .menuControl-max {
            display: flex;
            width: 50%;
            height: 100%;
        }
        .menuControl-min {
            display: none;
            width: 50%;
            height: 100%;
        }
        .searchBtn:hover {
            cursor: pointer;
            background-color: rgba(176, 176, 176, 0);
        }
        .titles {
            text-decoration: none;
            display: flex;
            height: 100%;
            width: 14%;
            padding-left: 5px;
            padding-right: 5px;
            align-items: center;
            justify-content: center;
            transition: color 0.3s ease;
            transition: background 1s ease;
            margin-right: 20px;
            font-size: 12px;
            color: rgb(60, 60, 60);
            border-radius: 5px;
        }
        .titles:hover {
            color: rgba(255, 255, 255, 0.757);
            background-color: rgba(0, 0, 0, 0.085);
        }
        .menuControl {
            display: none;
            justify-content: end;
            align-items: center;
            font-size: 30px;
            font-weight: bold;
            transition: display .5s ease;
            width: 100%;
            height: 100%;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
    @media only screen and (min-width: 1095px) {
        body {
        width: 100%;
        }
        .divider {
            border-right: 2px solid rgba(0, 0, 0, 0.116);
            border-left: 2px solid rgba(0, 0, 0, 0.116);
        }
        .header {
            width: 100%;
            background: linear-gradient(to bottom, rgba(35, 189, 224, 0.478), rgba(226, 24, 24, 0));
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
        }
        .logo {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30%;
            font-size: 30px;
            font-family: 'Courier New', Courier, monospace;
        }
        .menuControl-max {
            display: flex;
            width: 50%;
            height: 100%;
        }
        .menuControl-min {
            display: none;
            width: 50%;
            height: 100%;
        }
        .searchBtn:hover {
            cursor: pointer;
            background-color: rgba(176, 176, 176, 0);
        }
        .titles {
            text-decoration: none;
            display: flex;
            height: 100%;
            width: 14%;
            padding-left: 5px;
            padding-right: 5px;
            align-items: center;
            justify-content: center;
            transition: color 0.3s ease;
            transition: background 1s ease;
            margin-right: 20px;
            font-size: 16px;
            color: rgb(60, 60, 60);
            border-radius: 5px;
        }
        .titles:hover {
            color: rgba(255, 255, 255, 0.757);
            background-color: rgba(0, 0, 0, 0.085);
        }
        .menuControl {
            display: none;
            justify-content: end;
            align-items: center;
            font-size: 30px;
            font-weight: bold;
            transition: display .5s ease;
            width: 100%;
            height: 100%;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
    @media only screen and (max-width: 630px) {
        body {
        width: 100%;
        }
        .header {
            width: 100%;
            background: linear-gradient(to bottom, rgba(35, 189, 224, 0.478), rgba(226, 24, 24, 0));
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
        }
        .logo {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30%;
            font-size: 30px;
            font-family: 'Courier New', Courier, monospace;
        }
        .menuControl-max {
            display: none;
            width: 50%;
            height: 100%;
        }
        .menuControl-min {
            display: flex;
            justify-content: end;
            align-items: center;
            font-size: 30px;
            width: 50%;
            height: 100%;
        }
        .menuControl {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30px;
            font-weight: bold;
            width: 100%;
            height: 40px;
            padding-top: 10px;
            padding-bottom: 10px;
        }
        i {
            background-color: rgba(251, 251, 251, 0.375);
            padding: 8px;
            border-radius: 5px;
            color: rgba(0, 0, 0, 0.728);
            border: 1px solid rgba(0, 0, 0, 0.138);
        }
        i:hover {
            background-color: rgba(35, 189, 224, 0.127);
            cursor: pointer;
        }
        .titles {
            text-decoration: none;
            display: flex;
            height: 100%;
            width: 20%;
            align-items: center;
            justify-content: center;
            transition: color 0.3s ease;
            transition: background 1s ease;
            margin-right: 5px;
            font-size: 12px;
            color: rgb(60, 60, 60);
            border-radius: 3px;
        }
        .titles:hover {
            color: rgb(60, 60, 60);
            background-color: rgba(35, 189, 224, 0.278);
        }
        .none {
            display: none;

        }
        .border-right {
            border-right: 1px solid rgba(0, 0, 0, 0.473);
        }
    }
    
</style>