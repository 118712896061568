<template>
    <div class="navbar">
        <button @click="getWord1" class="navbar-btn">Kelimeler</button>
        <button @click="getWord2" class="navbar-btn">Irregularv2</button>
        <button @click="getWord3" class="navbar-btn">Irregularv3</button>
    </div>
    <div id="v2" class="container none">
        <div class="info">for Verb 2</div>
        <div class="rightBox">
            <button @click="showButton1">Show</button>
            <button @click="inewWord">Next</button>
        </div>
        <div class="leftBox">
            <div class="box">
                <div class="kelime">{{ iword.ingilizcev1 }}</div>,
                <div class="kelime">{{ iword.turkce }}</div>
            </div>
            <div id="box1" class="box none">
                <div class="kelime">{{ iword.ingilizcev2 }}</div>
            </div>
        </div>
    </div>
    <div id="v3" class="container none">
        <div class="info">for Verb 3</div>
        <div class="rightBox">
            <button @click="showButton2">Show</button>
            <button @click="inewWord">Next</button>
        </div>
        <div class="leftBox">
            <div class="box">
                <div class="kelime">{{ iword.ingilizcev1 }}</div>,
                <div class="kelime">{{ iword.turkce }}</div>
            </div>
            <div id="box2" class="box none">
                <div class="kelime">{{ iword.ingilizcev3 }}</div>
            </div>
        </div>
    </div>
    <div id="v1" class="container">
        <div class="info">{{ kelimeSayısı }} tane kelime içeriyor!</div>
        <div class="rightBox">
            <button @click="showButton3">Show</button>
            <button @click="newWord">Next</button>
        </div>
        <div class="leftBox">
            <div class="box">
                <div class="kelime">{{ kelime.ingilizce }}</div>
            </div>
            <div id="box3" class="box none">
                <div class="kelime">{{ kelime.turkce }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name : 'DictionaryGame',
        data(){
            return {
                kelimeler : [],
                kelime : {},
                showedWord : [],
                ishowedWord : [],
                kelimeSayısı : null,
                ikelimeSayısı : null,
                iWords : [],
                iword : {},
            }
        },
        methods : {
            showButton1(){
                setTimeout(()=>{
                    setTimeout(()=>{
                    document.querySelector('#box1').style.opacity = 1;
                    },1000)
                    document.querySelector('#box1').classList.remove('none')
                },1000)
            },
            showButton2(){
                setTimeout(()=>{
                    setTimeout(()=>{
                    document.querySelector('#box2').style.opacity = 1;
                    },1000)
                    document.querySelector('#box2').classList.remove('none')
                },1000)
            },
            showButton3(){
                setTimeout(()=>{
                    setTimeout(()=>{
                    document.querySelector('#box3').style.opacity = 1;
                    },1000)
                    document.querySelector('#box3').classList.remove('none')
                },1000)
            },
            getWord1(){
                document.querySelector('#v1').style.opacity = 1;
                document.querySelector('#v1').classList.remove('none')
                document.querySelector('#v2').style.opacity = 0;
                document.querySelector('#v3').style.opacity = 0;
                document.querySelector('#v2').classList.add('none')
                document.querySelector('#v3').classList.add('none')
            },
            getWord2(){
                document.querySelector('#v2').style.opacity = 1;
                document.querySelector('#v2').classList.remove('none')
                document.querySelector('#v1').style.opacity = 0;
                document.querySelector('#v3').style.opacity = 0;
                document.querySelector('#v1').classList.add('none')
                document.querySelector('#v3').classList.add('none')
            },
            getWord3(){
                document.querySelector('#v3').style.opacity = 1;
                document.querySelector('#v3').classList.remove('none')
                document.querySelector('#v1').style.opacity = 0;
                document.querySelector('#v2').style.opacity = 0;
                document.querySelector('#v2').classList.add('none')
                document.querySelector('#v1').classList.add('none')
            },
            newWord(){
                axios.get('words.json')
                    .then((response) => {
                        document.querySelector('#box3').classList.add('none')
                        setTimeout(() => {
                            document.querySelector('#box3').style.opacity = 0;
                        }, 1000)
                        this.kelimeler = Object.values(response.data)
                        let lenWords = this.kelimeler.length
                        let sayı = lenWords - Math.floor(Math.random() * lenWords)
                        const controlWord = (value) => {
                            if(this.showedWord.length === lenWords){
                                this.showedWord = []
                            }
                            if (this.showedWord.indexOf(value) === -1) {
                                this.showedWord.push(value)
                                this.kelime = this.kelimeler[value]
                            } else {
                                value = lenWords - Math.floor(Math.random() * lenWords)
                                controlWord(value)
                            }
                        }

                        controlWord(sayı)

                    })
                    .catch((e)=>{e})
            },
            inewWord(){
                axios.get('irregular_words.json')
                    .then((response) => {
                        document.querySelector('#box1').classList.add('none')
                        document.querySelector('#box2').classList.add('none')
                        setTimeout(() => {
                            document.querySelector('#box1').style.opacity = 0;
                            document.querySelector('#box2').style.opacity = 0;
                        }, 1000)
                        this.iWords = Object.values(response.data)
                        let ilenWords = this.iWords.length
                        let sayı = ilenWords - Math.floor(Math.random() * ilenWords)
                        const controlWord = (value) => {
                            if(this.ishowedWord.length === ilenWords){
                                this.ishowedWord = []
                            }
                            if (this.ishowedWord.indexOf(value) === -1) {
                                this.ishowedWord.push(value)
                                this.iword = this.iWords[value]
                            } else {
                                sayı = Math.floor(Math.random() * ilenWords)
                                controlWord(sayı)
                            }
                        }

                        controlWord(sayı)

                    })
                .catch((e)=>{ console.error(e) })
            }
        },
        created(){
            axios.get('words.json')
                .then((response)=>{
                    this.showedWord = []
                    this.kelimeler = Object.values(response.data)
                    let lenWords = this.kelimeler.length
                    this.kelimeSayısı = lenWords
                    let sayı = lenWords - Math.floor(Math.random()* lenWords)
                    this.kelime = this.kelimeler[sayı]
                })
                .catch((e)=>{e})
            axios.get('irregular_words.json')
                .then((response)=>{
                    this.iWords = Object.values(response.data)
                    let ilenWords = this.iWords.length
                    this.ikelimeSayısı = ilenWords
                    let sayı = ilenWords - Math.floor(Math.random()* ilenWords)
                    this.iword = this.iWords[sayı]
                })
                .catch((e)=>{e})
        }
    }
</script>

<style scoped>
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 600px;
    }
    .info {
        font-size: 12px;
        color: rgb(255, 255, 255);
        background-color: rgba(4, 163, 163, 0.871);
        padding: 10px;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.118);
        font-weight: bold;
        margin-top: 30px;
    }
    .box {  
        font-size: 30px;
        padding: 50px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(0, 0, 0, 0.542);
        font-weight: bold;
        width: 80%;
        background-color: rgba(214, 230, 230, 0.709);
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.163);
    }
    .navbar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .navbar-btn {
        width: 50%;
        font-size: 10px;
        background-color: rgba(4, 163, 163, 0.314);
        color: white;
    }
    .leftBox {
        display: flex;
        height: 80%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 50px;
    }
    .rightBox {
        height: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        padding: 20px;
    }
    button {
        font-size: 20px;
        font-weight: bold;
        border: 1px solid rgba(0, 0, 0, 0.129);
        color: rgba(0, 0, 0, 0.545);
        transition: background 0.4s ease;
        border-radius: 5px;
        padding: 20px;
        margin: 10px;
    }
    button:hover {
        background-color: rgba(163, 245, 163, 0.52);
        color: rgba(0, 0, 0, 0.619);
        cursor: pointer;
    }
    .kelime {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    #box1 {
        opacity: 0;
        transition: opacity 0.8s ease; 
    }
    #box2 {
        opacity: 0;
        transition: opacity 0.8s ease; 
    }
    #box3 {
        opacity: 0;
        transition: opacity 0.8s ease; 
    }
    .none {
        display: none;
    }

</style>