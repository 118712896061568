<template>
    <div>
        <div v-for="(post , index) in subjects" :key="index" class="box">
            <div class="title">
                {{ post.title }}
            </div>
            <hr>
            <div class="use-lang">
                <div v-for="language in post.languages" :key="language" class="languages">{{ language }}</div>
            </div>
            <hr>
            <router-link :to="'/subjects/' + newIndex" @click="sendIndex(post)" class="button">
                <div
                    
                    class="btnMore"
                >
                    Konu içeriğine bak...
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name : 'SubjectBox',
        props : {
            'subjects' : Array,
            'allSubjects' : Array
        },
        data(){
            return {
                newIndex : '',
                cutDesc : ''
            }
        },
        methods:{
            sendIndex(subject){
                let arr = Object.values(this.allSubjects)
                this.newIndex = arr.indexOf(subject)
            }
        }
    }
</script>

<style scoped>
    @media only screen and (min-width: 890px) {
        .box {
        width: 60%;
        margin-left: 19%;
        padding: 20px;
        border: 1px solid black;
        margin-top: 30px;
        border-radius: 5px;
        }
        .title {
            font-size: 20px;
            font-weight: bold;
        }
        .use-lang{
            display: flex;
            flex-wrap: wrap;
        }
        .languages {
            font-size: 15px;
            font-weight: bold;
            padding: 4px;
            border: 1px solid rgb(20, 23, 23);
            margin: 1px;
            border-radius: 5px;
        }
        .languages:hover {
            background-color: rgba(35, 189, 224, 0.478);
            padding-left: 10px;
            padding-right: 10px;
            font-size: 15px;
            cursor: none;
        }
        .short-desc {
            font-size: 15px;
        }
        .button {
            display: flex;
            justify-content: end;
            margin-top: 10px;
            text-decoration: none;
        }
        .btnMore {
            color: black;
            font-size: 15px;
            padding: 10px;
            background-color: rgba(35, 189, 224, 0.478);
            border-radius: 5px;
            border: 1px solid rgb(0, 0, 0);
            transition: padding .3s ease;
        }
        .btnMore:hover {
            background-color: rgb(255, 255, 255);
            padding-left: 15px;
            padding-right: 15px;
            cursor: pointer;
        }
    }
    @media only screen and (max-width: 890px) {
        .box {
        width: 60%;
        margin-left: 19%;
        padding: 20px;
        border: 1px solid black;
        margin-top: 30px;
        border-radius: 5px;
        }
        .title {
            font-size: 20px;
            font-weight: bold;
        }
        .use-lang{
            display: flex;
            flex-wrap: wrap;
        }
        .languages {
            font-size: 15px;
            font-weight: bold;
            padding: 4px;
            border: 1px solid rgb(20, 23, 23);
            margin: 1px;
            border-radius: 5px;
        }
        .languages:hover {
            background-color: rgba(35, 189, 224, 0.478);
            padding-left: 10px;
            padding-right: 10px;
            font-size: 15px;
            cursor: none;
        }
        .short-desc {
            font-size: 15px;
        }
        .button {
            display: flex;
            justify-content: end;
            text-decoration: none;
            margin-top: 10px;
        }
        .btnMore {
            color: black;
            text-decoration: none;
            font-size: 15px;
            padding: 10px;
            background-color: rgba(35, 189, 224, 0.478);
            border-radius: 5px;
            border: 1px solid rgb(0, 0, 0);
            transition: padding .3s ease;
        }
        .btnMore:hover {
            background-color: rgb(255, 255, 255);
            padding-left: 10px;
            padding-right: 10px;
            cursor: pointer;
        }
    }
</style>