<template>
    <div v-if="!tokenId" class="container">
        <div class="loginBox">
            Panel Acces Kod : 54
            <div class="idBox">
                <input class="loginInput" v-model="admin.id" placeholder="username" type="text">
            </div>
            <div class="idBox">
                <input class="loginInput" v-model="admin.pass" placeholder="pass" type="password">
            </div>
            <div class="idBox">
                <button class="loginBtn" @click="adminLogin">Giriş</button>
            </div>
        </div>
    </div>
    <div v-else class="container">
        <div class="projects-box">
            <div class="controlBtn">
                <button @click="adminLogout" class="logoControl"><i class="fas fa-power-off"></i></button>
            </div>
            <div class="dashTitles">Konular Dashboard</div>
            <div v-for="project in subjects" :key="project" class="project-box">
                <div class="project-title">{{ project.title }}</div>
                <div class="project-buttons">
                    <button @click="deleteSubject(project)" class="delete">
                    <i class="fas fa-trash"></i></button>
                </div>
            </div>
            <router-link
                class="goPage"
                to="/create/subject"
            >Konu Ekle</router-link>
            <router-link
                class="goPage"
                to="/subjects"
            >Sayfaya git</router-link>
        </div>
        <hr>
        <div class="posts-box">
            <div class="dashTitles">Gönderiler Dashboard</div>
            <div v-for="post in posts" :key="post" class="post-box">
                <div class="post-title">
                    {{ post.ftitle }}
                </div>
                <div class="post-buttons">
                    <button @click="upPost(post)" v-if="!post.future" class="up upbg">Öne Çıkar</button>
                    <button @click="downPost(post)" v-else class="up downbg">İptal</button>
                    <button @click="deletePost(post)" class="delete">
                    <i class="fas fa-trash"></i></button>
                </div>
            </div>
            <router-link
                class="goPage"
                to="/create/post"
            >Post Ekle</router-link>
        </div>
        <hr>
        <div class="projects-box">
            <div class="dashTitles">Projeler Dashboard</div>
            <div v-for="project in projects" :key="project" class="project-box">
                <div class="project-title">{{ project.title }}</div>
                <div class="project-buttons">
                    <button @click="deleteProject(project)" class="delete">
                    <i class="fas fa-trash"></i></button>
                </div>
            </div>
            <router-link
                class="goPage"
                to="/create/project"
            >Proje Ekle</router-link>
        </div>
        <hr>
        <div class="aboutPage">
            <div class="dashTitles">Hakkımda Sayfası</div>
            <div class="preWrite">
                <div class="preTitle">Önyazıyı güncelle</div>
                <div class="preSet">
                    <textarea :placeholder="pre" v-model="pre"></textarea>
                <button @click="updatePre" class="updatePre">Güncelle</button>
                </div>
            </div>
            <div class="skills">
                <div class="skillStat">
                    <div v-for="skill in skills" :key="skill" class="skill">
                        <div class="skillName">{{ skill }}</div>
                        <div class="deleteButton">
                            <button @click="skillDelete(skill)" class="skillDelete">
                            <i class="fas fa-trash"></i></button>
                        </div>
                    </div>
                </div>
                <div class="skillAdd">
                    <div class="skillTitle">Yetenek Ekle</div>
                    <div class="skillAddBox">
                        <input v-model="skill" class="skillInput" type="text">
                        <button @click="skillAdd" class="addButton">Ekle</button>
                    </div>
                </div>
            </div>
            <div class="courses">
                <div class="courseStat">
                    <div v-for="course in courses" :key="course" class="course">
                        <div class="courseName">{{ course }}</div>
                        <div class="deleteButton">
                            <button @click="courseDelete(course)" class="courseDelete">
                            <i class="fas fa-trash"></i></button>
                        </div>
                    </div>
                </div>
                <div class="courseAdd">
                    <div class="courseTitle">Kurs Ekle</div>
                    <div class="courseAddBox">
                        <input v-model="course" class="courseInput" type="text">
                        <button @click="courseAdd" class="addButton">Ekle</button>
                    </div>
                </div>
            </div>
            <div class="img">
                <div class="imgTitle">Fotoğraf Linki</div>
                <div class="imgUpdate">
                    <input v-model="img" :placeholder="img" class="imgInput" type="text">
                    <button @click="updateImg" class="addButton">Güncelle</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name : 'AdminPanel',
        data(){
            return {
                posts : [

                ],
                projects : [

                ],
                subjects : [],
                pre : '',
                skills : [],
                skill : '',
                courses : [],
                course : '',
                img : '',
                admin : {
                    id : '',
                    pass : ''
                },
                tokenId : '',
            }
        },
        created(){
            axios.get('posts.json')
                .then((response)=>{
                    let data = response.data
                    if(data){
                        this.posts = Object.values(data)
                    }
                })
            axios.get('projects.json')
                .then((response)=>{
                    let data = response.data
                    if(data){
                        this.projects = Object.values(data)
                    }
                })
            axios.get('subjects.json')
                .then((response)=>{
                    let data = response.data
                    if(data){
                        this.subjects = Object.values(data)
                    }
                })
            axios.get('token.json')
                .then(response=>{
                    this.tokenId = response.data.value
                })
                .catch((e)=>{e})
            axios.get('pre.json')
                .then((response)=>{
                    this.pre = response.data.pre
                })
                .catch((e)=>{e})
            axios.get('skills.json')
                .then((response)=>{
                    let data = response.data
                    this.skills = Object.values(data)
                })
                .catch((e)=>{e})
            axios.get('courses.json')
                .then((response)=>{
                    let data = response.data
                    this.courses = Object.values(data)
                })
                .catch((e)=>{e})
            axios.get('img.json')
                .then((response)=>{
                    this.img = response.data.img
                })
                .catch((e)=>{e})
        },
        methods:{
            courseAdd(){
                let course = this.course
                let courses = this.courses
                courses.push(course)
                axios.put('courses.json', courses )
                    .then((response)=>{
                        console.log(response.data)
                        this.course = ''
                    })
                    .catch((e)=>{e})
                
            },
            skillAdd(){
                let skill = this.skill
                let skills = this.skills
                skills.push(skill)
                axios.put('skills.json', skills )
                    .then((response)=>{
                        console.log(response.data)
                        this.skill = ''
                    })
                    .catch((e)=>{e})
                
            },
            updatePre(){
                let pre = this.pre
                axios.put('pre.json', { pre })
                .then(response=>{
                    console.log(response.data)
                })
                .catch((e)=>{e})
            },
            updateImg(){
                let img = this.img
                axios.put('img.json', { img })
                .then(response=>{
                    console.log(response.data)
                })
                .catch((e)=>{e})
            },
            upPost(post){
                let control = false
                console.log(control)
                let posts = this.posts
                for(let i=0 ; i < posts.length ; i++){
                    if(this.posts[i].future === true){
                        control = true
                    }
                }
                let index = posts.indexOf(post)
                if(!control){
                    posts[index] = {
                    ftitle : post.ftitle,
                    fsubtitle : post.fsubtitle,
                    fdesc : post.fdesc,
                    future : true,
                    fdate : post.fdate
                    }
                    axios.put('posts.json', posts)
                        .then((response)=>{
                            console.log(response.data)
                        })
                        .catch((e)=>{e})
                }else{
                    alert('Zaten öne çıkan bir öğe bulunuyor!')
                }
                
            },
            downPost(post){
                let posts = this.posts
                let index = posts.indexOf(post)
                posts[index] = {
                    ftitle : post.ftitle,
                    fsubtitle : post.fsubtitle,
                    fdesc : post.fdesc,
                    future : false,
                    fdate : post.fdate
                }
                axios.put('posts.json', posts)
                    .then((response)=>{
                        console.log(response.data)
                    })
                    .catch((e)=>{e})
            },
            deletePost(post){
                let posts = this.posts
                let index = posts.indexOf(post)
                posts.splice(index,1)
                axios.put('posts.json', posts)
                    .then((response)=>{
                        console.log(response.data)
                    })
                    .catch((e)=>{e})
            },
            deleteProject(project){
                let projects = this.projects
                let index = projects.indexOf(project)
                projects.splice(index,1)
                axios.put('projects.json', projects)
                    .then((response)=>{
                        console.log(response.data)
                    })
                    .catch((e)=>{e})
            },
            deleteSubject(subject){
                let subjects = this.subjects
                let index = subjects.indexOf(subject)
                subjects.splice(index,1)
                axios.put('subjects.json', subjects)
                    .then((response)=>{
                        console.log(response.data)
                    })
                    .catch((e)=>{e})
            },
            skillDelete(skill){
                let skills = this.skills
                let index = skills.indexOf(skill)
                skills.splice(index,1)
                axios.put('skills.json', skills)
                    .then((response)=>{
                        console.log(response.data)
                    })
                    .catch((e)=>{e})
            },
            courseDelete(course){
                let courses = this.courses
                let index = courses.indexOf(course)
                courses.splice(index,1)
                axios.put('courses.json', courses)
                    .then((response)=>{
                        console.log(response.data)
                    })
                    .catch((e)=>{e})
            },
            adminLogin(){
                axios.get('admin.json')
                    .then(response => {
                        let data = response.data
                        if(data.id === this.admin.id && data.pass === this.admin.pass) {
                            axios.put('token.json', { value : true })
                                .then((response=>{
                                    response
                                    window.location.href = '/admin'
                                }))
                                .catch((e)=>{e})
                        }else{
                            alert('Hatalı hesap girişi. Erişiminiz yok.')
                        }
                    })
                    .catch((e)=>{e})
            },
            adminLogout(){
                axios.put('token.json',{ value : false} )
                    .then((response=>{
                        console.log(response.data)
                        window.location.href = '/admin'
                    }))
                    .catch((e)=>{e})
            }
        }
    }
</script>

<style scoped>
    .container {
        width: 100%;
        height: 100%;
    }
    .posts-box {
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 15%;
        margin-bottom: 40px;
    }
    .post-box {
        width: 80%;
        margin-left: 10%;
        display: flex;
        padding: 10px;
        border: 1px solid rgba(0, 0, 0, 0.39);
        transition: background 0.5s ease;
        margin-top: 3px;
        border-radius: 10px;
    }
    .post-box:hover{
        background-color: rgb(142, 255, 179);
        cursor: grabbing;
    }
    .post-title {
        width: 70%;
        padding: 10px;
        font-size: 18px;
    }
    .post-buttons {
        width: 30%;
    }
    .up {
        width: 70%;
        height: 100%;
        font-weight: bold;
        color: rgb(73, 69, 69);
        border: 1px solid black;
        border-radius: 4px;
        cursor: pointer;
        transition: background .2s ease;
    }
    .up:hover {
        background-color: rgba(255, 255, 255, 0.446);
    }
    .upbg {
        background-color: rgb(187, 243, 165);
    }
    .downbg {
        background-color: rgba(236, 167, 102, 0.742);
    }
    .delete {
        width: 30%;
        height: 100%;
        background-color: rgba(212, 70, 70, 0.649);
        font-weight: bold;
        color: rgb(73, 69, 69);
        border: 1px solid black;
        border-radius: 4px;
        cursor: pointer;
        transition: background .2s ease;
    }
    .delete:hover {
        background-color: rgba(255, 0, 0, 0.72);
    }
    .projects-box {
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 15%;
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .project-box {
        width: 80%;
        margin-left: 10%;
        display: flex;
        padding: 10px;
        border: 1px solid rgba(0, 0, 0, 0.39);
        transition: background 0.5s ease;
        margin-top: 3px;
        border-radius: 10px;
    }
    .project-box:hover{
        background-color: rgb(142, 255, 179);
        cursor: grabbing;
    }
    .project-title {
        width: 70%;
        padding: 10px;
        font-size: 18px;
    }
    .project-buttons {
        display: flex;
        justify-content: end;
        width: 30%;
    }
    .delete {
        width: 30%;
        height: 100%;
        background-color: rgba(212, 70, 70, 0.649);
        font-weight: bold;
        color: rgb(73, 69, 69);
        border: 1px solid black;
        border-radius: 4px;
        cursor: pointer;
        transition: background .2s ease;
    }
    .delete:hover {
        background-color: rgba(255, 0, 0, 0.72);
    }
    .dashTitles {
        font-weight: bold;
        margin-bottom: 10px;
        border: 2px solid rgb(179, 195, 148);
        padding: 10px;
        border-radius: 20px;
        background-color: rgb(192, 238, 174);
    }
    .aboutPage {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 15%;
        width: 70%;
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .preWrite {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        padding: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .preTitle {
        width: 30%;
        font-size: 20px;
    }
    .preSet {
        display: flex;
        justify-content: center;
        align-items: end;
        width: 70%;
    }
    textarea {
        width: 80%;
        height: 200px
    }
    .updatePre{
        width: 20%;
        padding: 10px;
        background-color: rgb(192, 238, 174);
        border-radius: 10px;
        border: 2px solid rgba(0, 0, 0, 0.427);
        transition: background .3s ease;
        cursor: pointer;
    }
    .updatePre:hover {
        background-color: rgba(192, 238, 174, 0.58);
    }
    .skills {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 40px;
        border-top: 2px solid black;
        margin-bottom: 40px;
    }
    .skill:hover{
        background-color: rgb(142, 255, 179);
    }
    .course:hover{
        background-color: rgb(142, 255, 179);
    }
    .skillStat {
        display: flex;
        flex-wrap: wrap;
    }
    .skill{
        display: flex;
        padding: 5px;
        border-radius: 5px;
        border: 2px solid black;
        transition: background .3s ease;
        cursor: grabbing;
        margin: 8px;
    }
    .skillName {
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-weight: bold;
        padding: 5px;
        margin-right: 5px;
    }
    .deleteButton{
        width: 30%;
        display: flex;
        justify-content: end;
    }
    .skillDelete {
        padding: 5px;
        border-radius: 4px;
        transition: background .2s ease;
        background-color: rgba(212, 70, 70, 0.649);
        cursor: pointer;
    }
    .skillDelete:hover {
        background-color: rgb(212, 70, 70);
    }
    .skillAdd {
        display: flex;
    }
    .skillTitle {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        width: 30%;
    }
    .skillAddBox{
        width: 70%;
        display: flex;
    }
    .skillInput{
        width: 60%;
        font-size: 20px;
    }
    .addButton {
        width: 40%;
        background-color: rgb(192, 238, 174);
        border-radius: 10px;
        cursor: pointer;
    }
    .addButton:hover {
        background-color: rgba(192, 238, 174, 0.58);
    }
    .courses {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 40px;
        border-top: 2px solid black;
        margin-bottom: 40px;
    }
    .courseStat {
        display: flex;
        flex-wrap: wrap;
    }
    .course{
        display: flex;
        padding: 5px;
        border-radius: 5px;
        border: 2px solid black;
        transition: background .5s ease;
        cursor: grabbing;
        margin: 8px;
    }
    .courseName {
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-weight: bold;
        padding: 5px;
        margin-right: 5px;
    }
    .deleteButton{
        width: 30%;
        display: flex;
        justify-content: end;
    }
    .courseDelete {
        padding: 15px;
        border-radius: 4px;
        background-color: rgb(241, 145, 145);
        transition: background .2s ease;
        cursor: pointer;
    }
    .courseDelete:hover {
        background-color: rgb(246, 103, 103);
    }
    .courseAdd {
        display: flex;
    }
    .courseTitle {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        width: 30%;
        margin-top: 5px;
    }
    .courseAddBox{
        width: 70%;
        display: flex;
    }
    .courseInput{
        width: 60%;
        font-size: 20px;
    }
    .imgUpdate{
        width: 70%;
        display: flex;
    }
    .img {
        width: 100%;
        display: flex;
        margin-top: 100px;
    }
    .imgTitle {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        width: 30%;
        margin-top: 5px;
    }
    .imgInput {
       width: 60%;
       font-size: 18px;

    }
    .goPage {
        font-weight: bold;
        margin-top: 20px;
        text-decoration: none;
        color: rgb(60, 60, 60);
        border: 2px solid rgb(179, 195, 148);
        padding: 10px;
        border-radius: 20px;
        background-color: rgb(192, 238, 174);
        transition: background 0.5s ease;
    }
    .goPage:hover {
        border-radius: 30px;
        background-color: rgba(192, 238, 174, 0.461);
    }
    i{
        color: white;
    }
    .loginBox {
        width: 60%;
        margin-left: 20%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
    }
    .idBox {
        width: 80%;
        padding: 10px;
        display: flex;
        justify-content: center;
    }
    .loginInput {
        padding: 10px;
        font-size: 20px;
    }
    .loginBtn {
        width: 30%;
        padding: 10px;
        border-radius: 20px;
        border: 1px solid rgba(0, 0, 0, 0.314);
        background-color: rgba(149, 147, 150, 0.377);
        font-size: 15px;
        font-weight: bold;
        color: rgb(49, 49, 49);
        transition: width 1s ease;
    }
    .loginBtn:hover {
        width: 60%;
        background-color: aliceblue;
        cursor: pointer;
    }
    .controlBtn {
        padding: 20px;
        display: flex;
        width: 40%;
        justify-content: center;
    }
    .logoControl{
        background-color: rgb(239, 164, 164);
        padding: 20px;
        font-size: 20px;
        border-radius: 30px;
        border: none;
        transition: background .5s ease;
    }
    .logoControl:hover{
        cursor: pointer;
        background-color: rgb(203, 22, 22);
    }

</style>