<template>
    <div class="container">
        <div class="projectCard">
            <div class="projectTitle">{{ subject.title }}</div>
            <div class="projectLang" >
                <div class="languages" :key="language" v-for="language in languages">{{ language }}</div>
            </div>
            <div class="projectDesc">{{ subject.description }}</div>
            <div class="projectImg">
                <img :src="subject.img" alt="">
            </div>
            <div class="projectLink">
                <a target="_blank" :href="subject.link">
                    Ders ile ilgili bağlantı
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        name : 'SubjectDetail',
        data(){
            return {
                subjects : [],
                subject : {
                    title : 'Yükleniyor...',
                    description : 'Henüz bu adrese bir proje girişi yapılmamış olabilir!',
                },
                languages : ['Computer-Human Interaction']
            }
        },
        mounted(){
            const subjectId = this.$route.params.id;
            axios.get('subjects.json')
                .then(response=>{
                    let data = response.data
                    this.subjects = Object.values(data)
                    if(this.subjects[subjectId]){
                        this.subject = this.subjects[subjectId]
                        this.languages = this.subject.languages
                    }
                })
                .catch((e)=>{e})
        }
    }
</script>


<style scoped>
    @media only screen and (min-width: 570px){
        .container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        }
        .projectCard {
            width: 70%;
            min-height: 800px;
            display: flex;
            flex-direction: column;
        }
        .projectTitle {
            font-size: 50px;
            padding: 20px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.308);
        }
        .projectLang {
            display: flex;
            flex-wrap: wrap;
            padding: 10px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.308);
        }
        img{
            width: 60%;
            border-radius: 20px;
            margin-top: 50px;
            margin-left: 20%;
        }
        .languages {
            font-size: 18px;
            font-weight: bold;
            padding: 10px;
            border: 1px solid rgba(0, 0, 0, 0.438);
            transition: background .5s ease;
            margin: 5px;
            border-radius: 5px;
        }
        .languages:hover {
            background-color: rgba(35, 189, 224, 0.478);
            cursor: pointer;
        } 
        .projectDesc {
            padding: 20px;
            font-size: 18px;
        }
        .projectLink {
            display: flex;
            justify-content: end;
            padding: 20px;
        }
        a {
            text-decoration: none;
            font-size: 15px;
            background-color: rgba(35, 189, 224, 0.28);
            padding: 20px;
            border-radius: 20px;
            color: rgb(0, 0, 0);
            border: 1px solid rgba(0, 0, 0, 0.329);
        }
        a:hover {
            background-color: rgba(240, 255, 255, 0.421);
            font-weight: bold;
            font-size: 14px;
        }
    }
    @media only screen and (max-width: 570px){
        .container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        }
        .projectCard {
            width: 70%;
            min-height: 800px;
            display: flex;
            flex-direction: column;
        }
        .projectTitle {
            font-size: 30px;
            padding: 20px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.308);
        }
        .projectLang {
            display: flex;
            flex-wrap: wrap;
            padding: 5px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.308);
        }
        .languages {
            font-size: 14px;
            font-weight: bold;
            padding: 5px;
            border: 1px solid rgba(0, 0, 0, 0.438);
            transition: background .5s ease;
            margin: 5px;
            border-radius: 5px;
        }
        .languages:hover {
            background-color: rgba(35, 189, 224, 0.478);
            cursor: pointer;
        } 
        .projectDesc {
            padding: 20px;
            font-size: 14px;
        }
        .projectLink {
            display: flex;
            justify-content: end;
            padding: 20px;
        }
        a {
            text-decoration: none;
            font-size: 15px;
            background-color: rgba(35, 189, 224, 0.28);
            padding: 20px;
            border-radius: 20px;
            color: rgb(0, 0, 0);
            border: 1px solid rgba(0, 0, 0, 0.329);
        }
        a:hover {
            background-color: rgba(240, 255, 255, 0.421);
            font-weight: bold;
            font-size: 14px;
        }
    }

    


</style>