<template>
    <div class="container">
        <project-box
            :posts="boxPosts"
        ></project-box>
    </div>
</template>

<script>
    import ProjectBox from './ProjectBox.vue';
    import axios from 'axios';

    export default {
        name : 'ProjectsPage',
        components : {
            ProjectBox : ProjectBox
        },
        data(){
            return {
                boxPosts : [
                    {   
                        title : 'Yükleniyor',
                        languages : ['Yükleniyor'],
                        description : 'Yükleniyor',
                        link : ''
                    },
                    {   
                        title : 'Yükleniyor',
                        languages : ['Yükleniyor'],
                        description : 'Yükleniyor',
                        link : ''
                    },
                    {   
                        title : 'Yükleniyor',
                        languages : ['Yükleniyor'],
                        description : 'Yükleniyor',
                        link : ''
                    }
                ]
            }
        },
        created(){
            axios.get("/projects.json")
            .then((response)=>{
                this.boxPosts = response.data
            })
            .catch((e)=>{e})
        }
    }
</script>

<style scoped>
    .container {
        width: 100%;
        height: 100%;
    }
    
</style>