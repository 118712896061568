<template>
    <div class="body">
        <div class="title">Giriş Yap!</div>
        <div class="loginBox">
            <div class="idBox">
                <input class="loginInput" placeholder="username" type="text">
            </div>
            <div class="idBox">
                <input class="loginInput" placeholder="pass" type="password">
            </div>
            <div class="idBox">
                <button class="loginBtn">Giriş</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name : 'LoginPage',
        data(){

        }
    }
</script>


<style scoped> 
    .body {
        display: flex;
        flex-direction: column;
        min-height: 720px;
        align-items: center;
        justify-content: center;
    }
    .title {
        margin: 20px;
        padding-left: 20px;
        padding-right: 20px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 25px;
        height: 50px;
        color: rgba(0, 0, 0, 0.739);
    }
    .loginBox {
        background-color: rgba(35, 189, 224, 0.159);
        padding: 150px;
        border-radius: 20px;
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .idBox {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 5px;
        width: 50%;
    }
    .loginInput {
        padding: 8px;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.458);
    }
    .loginBtn {
        background-color: rgba(127, 255, 212, 0.427);
        color: rgba(0, 0, 0, 0.651);
        font-weight: bold;
        width: 90px;
        padding: 8px;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.458);
        transition: background 0.6s ease;
    }
    .loginBtn:hover {
        background-color: rgba(127, 255, 212, 0.8);
        cursor: pointer;
    }
</style>